import EntityForm from "@/mixins/EntityForm";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    pavilionService: "pavilionService",
    tagService: "tagService"
  }
})
export default class StandForm extends mixins(EntityForm, WithVisitorLevelPermissions) {
  model = {};
  pavilions = [];
  events = [];
  tags = [];

  get event() {
    if (!this.model.idPavilion) {
      return [];
    }
    const selectedPavilion = this.pavilions.filter(p => p.id == this.model.idPavilion);
    if (selectedPavilion.length == 0) {
      return [];
    }
    const idEvent = selectedPavilion[0].idEvent;
    const event = this.events.filter(e => e.id == idEvent);
    if (event.length == 0) {
      return null;
    }
    return event[0];
  }

  get helpLink() {
    return this.translations.pageHelp.stand;
  }

  get selectedLanguages() {
    const standEvent = this.event;
    if (standEvent === null) {
      return [];
    }
    return standEvent.languages;
  }

  get selectedLanguagesOptions() {
    const selectedLanguages = this.selectedLanguages
      .map((l) => {
        return {
          value: l.id,
          label: l.description
        };
      });
    return selectedLanguages;
  }

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "idPavilion",
            label: this.translations.labels.stand_form_pavilion,
            type: "select",
            items: this.pavilionsItems,
            required: true
          },
          {
            id: "code",
            label: this.translations.labels.event_form_code,
            type: "text",
            required: true,
            rules: "min:4"
          },
          {
            id: "displayOrder",
            label: "Display Order",
            type: "number",
            required: false,
            hint:this.translations.labels.event_form_stand_order,
            persistentHint:true,
            disabled: true
          },
          {
            id: "drawerHeight",
            label: this.translations.labels.event_form_drawerHeight,
            type: "slider",
            step: 5,
            max:100,
            min:30,
            required: false,
          },
          {
            id: "drawerEnabled",
            label: this.translations.labels.event_form_drawerEnabled,
            type: "switch",
            required: false,
          },
          {
            id: "tagsIds",
            label: this.translations.labels.stand_form_tags,
            items: this.tagsOptions,
            type: "autocomplete",
            multiple: true,
            resetColumns: true,
            columns: 2
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.selectedLanguages,
            resetColumns: true,
            elements: [
              {
                id: "name",
                label: this.translations.labels.stand_form_name,
                required: true,
                type: "text"
              },
              {
                id: "description",
                label: this.translations.labels.stand_form_description,
                required: true,
                type: "textarea"
              },
              {
                id: "previewImage",
                label: this.translations.labels.stand_form_preview,
                required: false,
                type: "media",
                mediaType: "image",
              },
              {
                id: "company",
                label: this.translations.labels.stand_form_company,
                required: true,
                type: "text"
              },
              {
                id: "videoIntro",
                label: this.translations.labels.stand_form_intro,
                required: false,
                type: "text"
              },
              {
                id: "isAutoplay",
                label: this.translations.labels.stand_form_isAutoplay,
                required: false,
                type: "switch"
              },
              {
                id: "isAllowFullscreen",
                label: this.translations.labels.stand_form_isAllowFullscreen,
                required: false,
                type: "switch"
              },
              {
                id: "isMute",
                label: this.translations.labels.stand_form_isMute,
                required: false,
                type: "switch"
              },
              {
                id: "videoPlatformType",
                label: this.translations.labels.stand_form_videoIntroPlatform,
                required: false,
                type: "select",
                items: [
                    "Default",
                  "YouTube"
                ],
              },
              {
                id: "imageUri",
                label: this.translations.labels.stand_form_imageUri,
                required: true,
                type: "media",
                mediaType: "background",
              }
            ],
          }
        ]
      },
      {
        id: "visitorLevels",
        type: "visitor-levels-permissions",
        resetColumns: true,
        transcludeModel: true,
        legend: this.translations.labels.visitor_level,
        label: this.translations.labels.visitor_level,
        levels: this.currentVisitorLevelsOptions
      }
    ];
  }

  get pavilionsItems() {
    return this.pavilions.map(p => {
      return {
        value: p.id,
        label: p.code
      };
    });
  }

  get tagsOptions() {
    return this.tags.map(t => {
      return {
        group: t.taxonomyCode,
        label: t.code,
        value: t.id
      };
    });
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {
  }

  async created() {
    this.pavilions = await this.pavilionService.list();
    this.events = await this.eventService.list();

    this.tags = await this.tagService.list();

    this.afterCreate();
  }
}